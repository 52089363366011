<template>
  <div>
    <!-- <AppHeader /> -->
    <v-divider></v-divider>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          overlay-color="yellow"
          width="100%"
          height="100vh"
          permanent
          color="grey"
        >
          <!-- <v-layout wrap>
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dense v-for="(main, i) in appMenu" :key="i">
                <v-layout wrap>
                  <v-flex xs12>
                    <router-link :to="main.route" style="text-decoration: none">
                      <v-list  active-class="activated">
                        <v-list-item-group >
                          <v-list-item 
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: white;
                              cursor: pointer;
                              font-family: pbold;
                            "
                          >
                            {{ main.name }}
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>

                   
                    </router-link>
                  </v-flex>
                </v-layout>
             
              </v-list>
            </v-flex>
          </v-layout> -->
         <v-list>
      <v-list-item-group v-model="model">
        <v-list-item class="pb-3"
          v-for="(item, i) in menus"
          :key="i"
        >
          <v-list-item-icon>
                <router-link :to="item.route" style="text-decoration: none">
            <v-icon color="#343666" x-samll v-text="item.icon"></v-icon>
            </router-link>
          </v-list-item-icon>
           
          <v-list-item-content>
              <router-link :to="item.route" style="text-decoration: none">
            <v-list-item-title   style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: white;
                              cursor: pointer;
                              font-family: pbold;
                            " v-text="item.name"></v-list-item-title>
                                 </router-link>
          </v-list-item-content>
        
        </v-list-item>
      </v-list-item-group>
    </v-list>
       
       
       
        </v-navigation-drawer>
      </v-flex>

      <!-- <v-list-group
                        no-action
                        :value="false"
                        active-class="activated"
                      >
                        <template v-slot:activator>
                          <v-list-item-title
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: white;
                              cursor: pointer;
                              font-family: pbold;
                            "
                            >{{ main.name }}</v-list-item-title
                          >
                        </template>

                        <v-flex xs12 py-2 px-4>
                          <div style="border-bottom: 1px dotted black"></div>
                        </v-flex>
                        <template v-for="(sub, i) in main.subMenu">
                        <v-flex xs12 text-left pl-4 :key="i">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <router-link
                                :to="sub.route"
                                style="text-decoration: none"
                              >
                                <span
                                  :style="
                                    $route.path == sub.route
                                      ? {
                                          'font-family': 'pbold',
                                          'font-size': ' 16px',
                                        }
                                      : {
                                          'font-family': 'pbold',
                                          'font-size': ' 13px',
                                        }
                                  "
                                  style="
                                    color: black;
                                    letter-spacing: 3px;
                                    cursor: pointer;
                                  "
                                >
                                  {{ sub.name }}
                                </span>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 py-2 px-4 :key="sub._d">
                          <div style="border-bottom: 1px solid black"></div>
                        </v-flex>
                      </template>
                      </v-list-group> -->

      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #FFFFFF'
              : 'background-color: #fff9c4'
          "
        >
          <v-flex xs12 pa-0>
            <v-card
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view :key="$route.fullPath"> </router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import AppHeader from "./../layout/AppHeader";
export default {
  components: {
    // AppHeader,
  },
  data() {
    return {
      model: 1,
      sideNav: true,
      admin: [{ name: "ADMINS", route: "/Admins" }],
      menus: [
        {
          name: "Dashboard",
          route: "/admin",
          icon:"mdi-view-dashboard"


          // subMenu: [
          //   {
          //     name: "board",
          //     route: "/admin",
          //   },
          // ],
        },

        {
          name: "Divisions",
          route: "/division",
            icon:"mdi-image-filter-hdr"
         
        },
        {
          name: "Snare Types",
          route: "/manageSnare",
            icon:"mdi-nix"
         
        },
        {
          name: "User List",
          route: "/userlist",
            icon:"mdi-account"
          
          // subMenu: [
          //   {
          //     name: "User List",
          //     route: "/userlist",
          //   },
          // ],
        },
        {
          name: "Reportings",
          route: "/reportings",
            icon:"mdi-page-layout-sidebar-left"

          // subMenu: [
          //   {
          //     name: "Reportings",
          //     route: "/reportings",
          //   },
          // ],
        },
        {
          name: "Trainings",
          route: "/trainings",
            icon:"mdi-run"

          // subMenu: [
          //   {
          //     name: "Trainings",
          //     route: "/trainings",
          //   },
          // ],
        },
        {
          name: "Animal Rescue",
          route: "/animalrescue",
            icon:"mdi-paw"

          // subMenu: [
          //   {
          //     name: "Animal rescue",
          //     route: "/animalrescue",
          //   },
          // ],
        },

        {
          name: "Privacy Policy",
          route: "/privacy",
            icon:"mdi-note"

          // subMenu: [
          //   {
          //     name: "Privacy Policy",
          //     route: "/privacy",
          //   },
          // ],
        },
      ],
      menus2: [
        {
          name: "Dashboard",
          subMenu: [
            {
              name: "board",
              route: "/admin",
            },
          ],
        },

        {
          name: "Divisions",
          subMenu: [
            {
              name: "Divisions",
              route: "/division",
            },
          ],
        },
        {
          name: "User List",
          subMenu: [
            {
              name: "User List",
              route: "/userlist",
            },
          ],
        },
        {
          name: "Reportings",
          subMenu: [
            {
              name: "Reportings",
              route: "/reportings",
            },
          ],
        },
        {
          name: "Trainings",
          subMenu: [
            {
              name: "Trainings",
              route: "/trainings",
            },
          ],
        },
        {
          name: "Animal Rescue",
          subMenu: [
            {
              name: "Animal rescue",
              route: "/animalrescue",
            },
          ],
        },

        {
          name: "Privacy Policy",
          subMenu: [
            {
              name: "Privacy Policy",
              route: "/privacy",
            },
          ],
        },
      ],
    };
  },
  computed: {
    appType() {
      return this.$store.state.userType;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      return this.menus;
    },
    navItems() {
      if (this.appType == "subadmin") return this.userNavItemsSubAdmin;
      else if (this.appType == "programadmin")
        return this.userNavItemsProgramAdmin;
      else if (this.appType == "staticcontentadmin")
        return this.userNavItemsStaticAdmin;
      else if (this.appType == "accountsadmin")
        return this.userNavItemsAccountsAdmin;
      else return this.userNavItems;
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
/* .activated { */
.activated {
  background-color: white !important;
}
/* } */

/* .red_list  .v-list-item--active {
  background-color: red;
  color: white;
} */
</style>
